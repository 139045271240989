
import { Options, Vue } from "vue-class-component";
import BackToInvoicesButton from "@/components/tuition-fees/BackToInvoicesButton.vue";
import MirroringUserBanner from "@/components/tuition-fees/MirroringUserBanner.vue";
import { SessionStorageKeys } from "@/constants/constants";
import UnauthorizedHelper from "@/helpers/tuitionfees/unauthorized-helper";
import { TfPerson } from "@/interfaces/api";
import SessionStorageService from "@/services/sessionstorage-service";

@Options({
  components: { BackToInvoicesButton, MirroringUserBanner },
  data: () => ({
    /**
     * The payment response retrieved from session storage
     * Stored in session storage by CardPayment.vue
     */
    paymentResponse: "",
    /**
     * The personal id of the user being mirrored by admin
     */
    personalId: "",
    /**
     * The name of the user being mirrored by admin
     */
    personName: "",
    /**
     * The lucat id of the user being mirrored by admin
     */
    lucatId: "",
    /**
     * A boolean to indicate if tfp-admin is mirroring user or not
     */
    isMirroringUser: false,
  }),
  computed: {
    /**
     * The mailto link for contacting servicedesk
     */
    mailToLink() {
      return `
      mailto:servicedesk@lu.se?subject=Card%20payment%20failed&body=${this.paymentResponse}`;
    },
  },
  methods: {
    /**
     * Gets the payment response from session storage
     * Stored in session storage by CardPayment.vue
     */
    getPaymentResponse(): void {
      this.$store.commit("showSpinner", false);

      const paymentResponse = SessionStorageService.getItem(
        SessionStorageKeys.PaymentResponse
      );

      this.paymentResponse = paymentResponse;

      if (!this.paymentResponse) {
        this.paymentResponse = "Unknown error";
      }
    },
    /**
     * Gets the personal id, lucat id and name of the person being mirrored
     * Stored in session storage by CardPayment.vue
     */
    getUserId(): void {
      const mirroredUser: TfPerson | undefined = SessionStorageService.getItem(
        SessionStorageKeys.MirroredUser
      );

      if (mirroredUser) {
        this.personName = mirroredUser.displayName;
        this.personalId = mirroredUser.personalId;
        this.lucatId = mirroredUser.luEduPersonPrimaryId;
      }
    },
    /**
     * If not logged in or not a student, redirect to unauthorized page
     * Otherwise, check if admin is mirroring a user, if so show banner
     * if not redirect to unauthorized page
     */
    checkIfUserIsStudentOrAdminMirroringUser(): void {
      this.currentUser = this.$store.getters.getCurrentUser;
      this.isMirroringUser =
        UnauthorizedHelper.checkIfUserIsStudentOrAdminMirroringUser(
          this.currentUser
        );
    },
  },
  mounted() {
    this.getUserId();
    this.getPaymentResponse();
    this.checkIfUserIsStudentOrAdminMirroringUser();
  },
  unmounted() {
    SessionStorageService.deleteItem(SessionStorageKeys.PaymentResponse);
  },
})
export default class PaymentResponse extends Vue {}
