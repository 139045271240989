
import { Options, Vue } from "vue-class-component";

@Options({
  methods: {
    /**
     * Redirects to the tuition fee home page
     */
    goToTuitionFeeHome(): void {
      this.$router.push({
        name: "TuitionFeeHome",
      });
    },
  },
})
export default class BackToInvoicesButton extends Vue {}
